.Person {
    width: 60%;
    margin: 16px auto;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding: 16px;
    text-align: center;
  }
  
  @media (min-width: 500px) {
      .Person {
          width: 450px;
      }
  }