.movie {
    margin: 1rem;
    padding: 1rem;
    background-color: #230052;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 12px;
    text-align: center;
    color: white;
  }
  
  .movie h2 {
    font-size: 2rem;
    color: #f7e702;
  }
  
  .movie h3 {
    color: #eccf77;
    margin: 0;
    font-size: 1rem;
  }