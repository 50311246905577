.Styles {
  text-align: center;
}

.red {
  color: red;
}

.bold {
  font-weight: bold;
}

.Styles button {
  background-color: green;
  color: white;
  font: inherit;
  border: 1px solid blue;
  padding: 8px;
  cursor: pointer;
}

.Styles button:hover {
  background-color: lightgreen;
  color: black;
}

.Styles button.Red {
  background-color: red;
  color: white;
  font: inherit;
  border: 1px solid blue;
  padding: 8px;
  cursor: pointer;
}

.Styles button.Red:hover {
  background-color: salmon;
}
